<template>
  <div>
    <el-dialog
      title="状态变更记录"
      :visible.sync="visible"
      width="60%"
      :before-close="handleClose"
    >
      <el-table
        :data="tableData"
        size="mini"
        border
        highlight-current-row
        style="width: 100%">
        <el-table-column
          align="center"
          type="index"
          label="序号">
        </el-table-column>
        <el-table-column
          prop="user_name"
          label="提交人"
          align="center">
        </el-table-column>
        <el-table-column
          prop="create_at"
          align="center"
          label="提交时间">
        </el-table-column>
        <el-table-column
          prop="type"
          align="center"
          label="类型">
          <template slot-scope="scope">
            <div v-if="scope.row.type === 1">上线</div>
            <div v-if="scope.row.type === 2">下线</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="status_info"
          align="center"
          label="上线/下线结果"
          width="180">
        </el-table-column>
        <el-table-column
          align="center"
          label="详情查看"
          width="180">
          <template slot-scope="scope">
            <el-button type="text" @click="handleView(scope.row, 'view')">查 看</el-button>
            <el-button v-if="scope.row.status == 0" type="text" @click="handleView(scope.row, 'edit')">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="footer" style="text-align:center;">
        <el-button @click="handleAdd" type="primary" size="small">新增</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "StatusChangeDialog",
  props: ["visible", "localeId"],
  data() {
    return {
      tableData: [],
      total: 0
    }
  },
  mounted() {
    this.monitor()
  },
  watch: {
    localeId: {
      handler: function (val) {
        this.getData()
      },
    }
  },
  methods: {
    getData() {
      this.$get("admin/offline_apply", {
        locale_id: this.localeId
      }).then(res => {
        this.tableData = res.list
        this.total = res.count
      })
    },
    monitor(){
      this.$on("getData", () => {
        this.getData()
      })
    },
    handleClose() {
      this.$emit('handleClose')
    },
    handleView(row, mode){
      this.$emit('handleStatusView',row.id, mode)
    },
    handleAdd(){
      this.$emit('handleStatusAdd',this.localeId)
    }
  }
}
</script>
<style scoped lang="scss">
</style>
