<!--在线监管-健康码管理-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-input
            v-model.trim="filter.mn"
            placeholder="编码"
            @keyup.native.enter.stop="getData"
          >
            <el-button
              slot="prepend"
              icon="el-icon-refresh"
              @click="handleRefresh"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            placeholder="油烟健康码"
            v-model="filter.HealthCodeColor"
            @change="getData"
            clearable
          >
            <el-option label="红色" value="Red"/>
            <el-option label="黄色" value="Yellow"/>
            <el-option label="绿色" value="Green"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-cascader
            v-model="filter.owner"
            @change="getData"
            placeholder="所属单位"
            :options="customerTree"
            :props="orgProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="filter.LocaleWithDevice" @change="getData"
          >历史监测点
          </el-checkbox
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="openExportSizeInput"
            :loading="exportLoading"
          >导出
          </el-button
          >
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="showMN">显示设备编号</el-checkbox>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        ref="HealthCodeTable"
        :data="datalist.content"
        @sort-change="handleSort"
        @selection-change="handleSelectionChange"
        show-summary
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="selection" align="center" width="55" :selectable="canSelectRow"/>
        <el-table-column type="index" label="#" align="center" width="55"/>
        <el-table-column
          prop="Name"
          sortable="custom"
          label="监测点"
          width="160"
          show-overflow-tooltip
          header-align="center"
        >
          <template slot-scope="{ row }">
            <el-button type="text" @click="handlePdfRecord(row)">{{
                row.Name
              }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="MN"
          v-if="showMN"
          sortable="custom"
          label="设备编号"
          align="center"
          width="140"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="StartAt"
          :formatter="dateFormat"
          label="起始日期"
          align="center"
          width="100"
          header-align="center"
        />
        <el-table-column
          prop="CompliantDays"
          label="达标天数"
          width="100"
          sortable="custom"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.CompliantDays || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ExcessNum"
          label="超标天数"
          width="100"
          sortable="custom"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.ExcessNum || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="FilterAbnormallyUsedDays"
          label="不正常使用净化器天数"
          sortable="custom"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.FilterAbnormallyUsedDays || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="OfflineCausedByShopDays"
          label="店家原因离线天数"
          sortable="custom"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.OfflineCausedByShopDays || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="CEmissions"
          label="昨日油烟折算浓度"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.CEmissions || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="CGranule"
          label="昨日颗粒物折算浓度"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.CGranule || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="CHydrocarbon"
          label="昨日非甲烷总烃折算浓度"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.CHydrocarbon || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="HealthCodeColor"
          width="110"
          label="油烟健康码"
          sortable="custom"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span class="iconfont" :class="scope.row.HealthCodeColor"
            >&#xe704;</span
            >
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
          align="center"
          width="170"
          header-align="center"
        >
          <template slot-scope="scope" v-if="filter.option">
            <i
              title="超标详情分析表"
              @click="handleAlarmRecord(scope.row.LocaleId, 2, scope.row.Owner)"
              class="opt fa el-icon-notebook-2"
            />
            <i
              title="日排放浓度表"
              @click="handleVline(
                scope.row.LocaleId,
                scope.row.Owner,
                scope.row.EmissionsSill,
                scope.row.GranuleSill,
                scope.row.HydrocarbonSill
              )"
              class="opt el-icon-s-data"
            />
            <i
              title="监测点信息"
              class="opt el-icon-orange"
              @click="handleObservation(scope.row)"
            ></i>
            <i
              title="状态变更记录"
              @click="handleStatusChange(scope.row)"
              style="color: #6badfb"
              class="opt el-icon-document"
            />
            <i
              title="红码处理记录"
              class="opt el-icon-tickets"
              @click="handleShowHealthCodeRecord(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <!-- 监测点详情 弹窗 beginning -->
    <observationDialog
      :show="isShowObservationDialog"
      :showData="isShowObservationDialogData"
      @on-close="isShowObservationDialog = false"
    ></observationDialog>
    <!-- 监测点详情 弹窗 end -->

    <!--状态变更记录弹窗-->
    <status-change-dialog
      ref="statusChange"
      :visible.sync="statusChangeVisible"
      :locale-id="localeId"
      @handleClose="handleStatusClose"
      @handleStatusAdd="handleStatusToAdd"
      @handleStatusView="handleStatusToView"
    />
    <!--状态变更记录弹窗查看功能-->
    <offline-view-dialog
      :id="id"
      :visible.sync="statusViewVisible"
      :mode="mode"
      @handleClose="statusViewVisible = false"
    />
    <!--状态变更记录弹窗新增功能-->
    <offline-apply-dialog
      ref="offlineApply"
      :visible.sync="statusAddVisible"
      :locale-id="id"
      @handleClose="handleApplyClose"
    />

    <!-- 底部工具条 -->
    <el-col :span="24" class="toolbar">
      <el-button
        type="primary"
        @click="handleDistribute"
        size="mini"
      >核实派单
      </el-button>
      <el-pagination
        small
        background
        @size-change="(v) => handlePageChange(v, 'size')"
        @current-change="(v) => handlePageChange(v, 'page')"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      @close="handlerClose"
    >
      <span slot="title"
      ><strong>{{ owner }}</strong></span
      >
      <template>
        <el-form :inline="true" size="mini">
          <el-form-item>
            <el-date-picker
              v-model="filter.timerange"
              type="daterange"
              value-format="timestamp"
              @change="handleVline"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-download"
              @click="handleExport"
            >导出
            </el-button
            >
          </el-form-item>
        </el-form>
        <!-- <ve-line :data="dataOption" :settings="chartSettings" :grid="grid" :data-empty="dataOption.rows.length===0" /> -->
        <ve-histogram
          :data="dataOption"
          :mark-line="markLine"
          :extend="extend"
          :colors="['#00A1FF', '#F5B049', '#009999']"
          :settings="chartSettings"
          :grid="grid"
          :data-empty="dataOption.rows.length === 0"
        />
      </template>
    </el-dialog>
    <!--  红码处理记录  -->
    <el-dialog width="60%" :visible.sync="HealthCodeRecordDialog.visible" title="红码处理记录" :center="true">
      <el-table
        :data="HealthCodeRecordDialog.data"
        v-loading="HealthCodeRecordDialog.loading"
        size="mini"
        border
      >
        <el-table-column type="index" label="#" align="center" width="55"/>
        <el-table-column
          prop="StartAt"
          :formatter="dateFormat"
          label="起始日期"
          align="center"
          width="100"
          header-align="center"
        />
        <el-table-column
          prop="ExcessDays"
          label="超标天数"
          width="100"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.ExcessDays || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="FilterAbnormallyUsedDays"
          label="不正常使用净化器天数"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.FilterAbnormallyUsedDays || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="OfflineCausedByShopDays"
          label="店家原因离线天数"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.OfflineCausedByShopDays || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="VerifyAt"
          :formatter="dateFormat"
          label="核实日期"
          align="center"
          width="100"
          header-align="center"
        />
        <el-table-column
          prop="Verifier"
          label="核实人员"
          align="center"
          width="100"
          header-align="center"
        />
        <el-table-column
          prop="Cause"
          label="核实结果"
          align="center"
          width="100"
          header-align="center"
        />
        <el-table-column
          prop="HandleAt"
          :formatter="dateFormat"
          label="处理日期"
          align="center"
          width="100"
          header-align="center"
        />
        <el-table-column
          prop="Handler"
          label="处理人员"
          align="center"
          width="100"
          header-align="center"
        />
        <el-table-column
          prop="Resolution"
          label="处理结果"
          align="center"
          width="100"
          header-align="center"
        />
        <el-table-column
          label="操作"
          align="center"
          width="120"
          fixed="right"
          header-align="center"
        >
          <template slot-scope="scope">
            <i
              v-if="scope.row.Status !== 'FINISHED'"
              title="编辑"
              @click="handleCodeView(scope.row.Id, 'edit')"
              class="opt el-icon-orange el-icon-edit"
            />
            <i
              title="查看"
              @click="handleCodeView(scope.row.Id, 'view')"
              class="opt el-icon-orange el-icon-view"
            />
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="footer" style="text-align:center;">
        <el-button @click="handleAddHealthCode" type="primary" size="small">核实派单</el-button>
      </div>
    </el-dialog>
    <!--  一点一档  -->
    <el-dialog
      :visible.sync="NotificationDialog.visible"
      style="position: fixed !important;
      top: 50% ;
      left: 50% ;
      width: 110%;
      height: 120%;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      transform: translate(-50%, -50%) !important;
      z-index: 9999 !important;"
    >
      <div style="max-height: calc(100vh - 200px);overflow-y: auto;">
        <notification7Dialog
          :device="NotificationDialog.data"
        ></notification7Dialog>
      </div>
    </el-dialog>
    <el-dialog
      title="超标详情分析表"
      :visible.sync="isShowOverTimeDialog"
      @open="getAlarmRecordData(overDialogLocaleId, overDialogOwner)"
      @closed="setOverTimeDialogDefault()"
    >
      <overTimeDialog
        ref="childDialog"
        :localeId="overDialogLocaleId"
        :owner="overDialogOwner"
      ></overTimeDialog>
    </el-dialog>
    <management-view-dialog ref="managementViewDialog" @on-refresh="getHealthCodeRecode"  />
  </section>
</template>
<script>
import {mapState} from 'vuex'
import 'v-charts/lib/style.css'
import ManagementViewDialog from "@/component/healthCodeManagement/ManagementViewDialog.vue";
import VeHistogram from 'v-charts/lib/histogram.common'
import {
  join,
  dateFormater,
  stg,
  getUserInfo,
  underline,
  getSinglePname,
  getPname,
  export_json,
  dataFormatter,
  base64ToBlob, ownerJoin
} from '@/util/index'
import observationDialog from '@/component/ObservationDialog'
import notification7Dialog from '@/component/Notification7Dialog'
import overTimeDialog from '@/component/overTimeDialog'
import StatusChangeDialog from "@/component/locale/StatusChangeDialog.vue";
import OfflineViewDialog from "@/component/offlineManagement/OfflineViewDialog.vue";
import OfflineApplyDialog from "@/component/locale/OfflineApplyDialog.vue";

export default {
  components: {
    OfflineApplyDialog,
    OfflineViewDialog,
    StatusChangeDialog,
    VeHistogram, observationDialog, notification7Dialog, overTimeDialog, ManagementViewDialog},
  data() {
    this.chartSettings = {
      metrics: ['油烟折算浓度', '颗粒物折算浓度', '非甲烷总烃折算浓度'],
      dimension: ['采集时间'],
      // xAxisType: 'time',
      yAxisName: ['单位:mg/m³']
    }
    this.extend = {
      // series: {
      //   barWidth: 20
      // },
      'xAxis.0.axisLabel.rotate': 45
    }
    this.grid = {
      show: true,
      top: 50,
      left: 10
      // backgroundColor: '#404a59'
    }
    return {
      statusAddVisible: false,// 状态变更记录弹窗新增功能
      localeId: '',
      statusChangeVisible: false,//状态变更记录弹窗
      tableSelection: [],
      isShowObservationDialog: false, // 是否显示监测点详情弹窗
      isShowObservationDialogData: {}, // 监测点详情弹窗
      url:
        'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ],
      dataOption: {
        columns: [
          '采集时间',
          '油烟折算浓度',
          '颗粒物折算浓度',
          '非甲烷总烃折算浓度'
        ],
        rows: []
      },
      exportLoading: false,
      loading: false,
      datalist: {},
      markLine: null,
      mn: null,
      LocaleId: null,
      userInfo: {},
      typ: null,
      owner: null,
      dlg: {
        visible: false,
        type: null,
        title: ''
      },
      filter: {
        option: true,
        HealthCodeColor: null,
        timerange: null,
        by: 'status',
        mode: 'asc',
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      },
      isShowOverTimeDialog: false,
      HealthCodeRecordDialog: {
        loading: false,
        visible: false,
        data: null
      },
      NotificationDialog: {
        visible: false,
        data: null
      },
      showMN: false,
      overDialogLocaleId: '',
      overDialogOwner: '',
      healthCodeInfo:{},
      statusViewVisible: false,
      id: "",
      mode: "",
    }
  },
  computed: {
    ...mapState(['clientHeight', 'customerTree', 'areaTree', 'user']),
    ...mapState({
      orgProps: (state) =>
        Object.assign({}, state.props, {label: 'Org', checkStrictly: true})
    })
  },
  filters: {
    filterReason(type) {
      const op = {
        ShopClosed: '店家歇业(装修)',
        DetectorFailure: '监测仪故障',
        OfflineCausedByShop: '店家原因导致离线'
      }
      return op[type]
    }
  },
  created() {
    this.userInfo = getUserInfo()
    this.getData()
  },
  methods: {
    canSelectRow(row, index) {
      return row.HealthCodeColor === 'Red'
    },
    handleDistribute() {
      const payload = {
        records: this.tableSelection.map(item => {
          return {
            LocaleId: item.LocaleId,
            Mn: item.MN,
            Owner: item.Owner,
            StartAt: item.StartAt,
            ExcessDays: item.ExcessNum || 0,
            CompliantDays: item.CompliantDays || 0,
            FilterAbnormallyUsedDays: item.FilterAbnormallyUsedDays || 0,
            OfflineCausedByShopDays: item.OfflineCausedByShopDays || 0
          }
        })
      }
      this.$post('admin/createHealthCodeRecord', payload)
        .then(res => {
          this.$message({
            type: 'success',
            message: res
          })
          this.handleRefresh()
        })
    },
    handleShowHealthCodeRecord(row) {
      this.healthCodeInfo = row
      this.getHealthCodeRecode()
    },
    getHealthCodeRecode() {
      this.HealthCodeRecordDialog.loading = true
      this.HealthCodeRecordDialog.visible = true
      const payload = {
        Param: {}
      }
      if (this.healthCodeInfo.LocaleId) {
        payload.Param['health_code_maintenance.locale_id'] = {S: 0, V: `'${this.healthCodeInfo.LocaleId}'`}
      }
      this.$post('admin/listHealthCodeRecord', payload).then(res => {
        this.HealthCodeRecordDialog.data = res.content
        this.HealthCodeRecordDialog.loading = false
      })
    },
    handleSelectionChange(val) {
      this.tableSelection = val
    },
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleDownload(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    //状态变更记录弹窗打开
    handleStatusChange(row) {
      this.statusChangeVisible = true
      this.localeId = row.LocaleId
    },
    //状态变更记录弹窗关闭
    handleStatusClose() {
      this.statusChangeVisible = false
    },
    //状态变更记录弹窗功能新增
    handleStatusToAdd(id) {
      this.id = id
      this.statusAddVisible = true
    },
    //状态变更记录弹窗功能查看
    handleStatusToView(id, mode) {
      this.id = id
      this.mode = mode
      this.statusViewVisible = true
    },
    //状态变更记录弹窗新增功能
    handleApplyClose() {
      this.statusAddVisible = false
      this.$refs.statusChange.getData()
    },
    /**
     * @description 下线监测点
     * @param row
     */
    //6月9日由下线变更为状态变更记录
    // handleOffline(row) {
    //   const param = Object.assign({}, row)
    //   this.$prompt('请输入下线原因', '下线', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消'
    //   }).then(({value}) => {
    //     param['Id'] = param.LocaleId
    //     delete param.LocaleId
    //     param['Cause'] = value
    //     param['StatusOfRecord'] = 'OFFLINE'
    //     this.$post('admin/saveLocale', param)
    //   }).catch(() => {
    //     this.$message('取消')
    //   })
    // },
    setOverTimeDialogDefault() {
      this.$refs.childDialog.setDefault()
    },
    // 查看历史一点一档
    handleLook(row) {
      this.download(row.LocaleId, row.Archive)
    },
    // 下载图片
    download(name, imgData) {
      this.downloadFile(name, imgData)
    },
    // 下载
    downloadFile(fileName, content) {
      const aLink = document.createElement('a')
      const blob = base64ToBlob(content) // new Blob([content]);
      const evt = document.createEvent('HTMLEvents')
      evt.initEvent('click', true, true) // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName
      aLink.href = URL.createObjectURL(blob)
      // aLink.dispatchEvent(evt);
      // aLink.click()
      aLink.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      ) // 兼容火狐
    },
    handleObservation(obj) {
      const par = {Param: {id: obj.LocaleId}}
      this.$post('admin/listLocale', par)
        .then((res) => {
          this.isShowObservationDialog = true
          this.isShowObservationDialogData = res.content[0]
          if (res.content[0].Owner) {
            this.isShowObservationDialogData.Owner = getPname(
              this.customerTree,
              res.content[0].Owner,
              'Org'
            ).join('/')
          }
          if (res.content[0].AreaId) {
            this.isShowObservationDialogData.AreaId = getSinglePname(
              this.areaTree,
              res.content[0].AreaId
            )
          }
        })
        .catch(() => {
        })
    },
    getData: function () {
      this.loading = true
      var para = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        Typ: 2,
        SortBy: this.filter.by,
        SortMode: this.filter.mode
      }
      para.Param = {}
      if (this.filter.HealthCodeColor) {
        para.Param['HealthCodeColor'] = this.filter.HealthCodeColor
      }
      if (this.filter.mn) {
        para.Param['mn'] = this.filter.mn
      }
      if (this.filter.owner && this.filter.owner.length !== 0) {
        para.Param['Owner'] = join(this.filter.owner)
      }
      if (!this.filter.timerange) {
        this.filter.timerange = []
        const now = new Date()
        now.setDate(now.getDate() - 1)
        now.setHours(0, 0, 0, 0)
        this.filter.timerange[1] = now.getTime()
        now.setDate(now.getDate() - 30)
        this.filter.timerange[0] = now.getTime()
      }
      para.Param['StartAt'] = this.filter.timerange[0] / 1000
      para.Param['EndAt'] = this.filter.timerange[1] / 1000
      para.Param['LocaleWithDevice'] = !this.filter.LocaleWithDevice
      this.$post('admin/listHealthCode', para).then((res) => {
        this.datalist = res
        this.loading = false
      })
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property])
    },
    userFormatter(r, c) {
      return dataFormatter(r[c.property], this.user)
    },
    doneFormatter(r, c, v) {
      if (c.property === 'DownDone') {
        return r.DownNum - v
      }
      return r.ExcessNum - v
    },
    customerFormatter(r, c) {
      // return getPname(this.customerTree, r[c.property], 'Org').join('/')
    },
    handleOption() {
      this.filter.page = 1
      this.filter.by = 'mn'
      this.filter.mode = 'asc'
      this.getData()
    },
    handleAlarmRecord(id, typ, owner) {
      this.overDialogLocaleId = id
      this.overDialogOwner = owner
      this.LocaleId = id
      this.typ = typ
      this.owner = getPname(this.customerTree, owner, 'Org').join('/')
      this.isShowOverTimeDialog = true
    },
    getAlarmRecordData(id, owner) {
      const last1day = this.getNextDate(-1)
      const last30day = this.getNextDate(-30)
      this.$nextTick(() => {
        this.$refs['childDialog'].getHoursDatas(id, owner, last1day)
        this.$refs['childDialog'].getMonthData(id, owner, last30day, last1day)
      })
    },
    getNextDate(day) {
      var dd = new Date()
      dd.setDate(dd.getDate() + day)
      var y = dd.getFullYear()
      var m =
        dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
      var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
      return y + '-' + m + '-' + d
    },
    handleSort(c) {
      if (c.order) {
        this.filter.by = underline(c.prop)
        this.filter.mode = c.order.replace(/ending/g, '')
      }
      this.getData()
    },
    handleVline(LocaleId, owner, sill, GranuleSill, HydrocarbonSill) {
      if (typeof LocaleId === 'string') {
        this.LocaleId = LocaleId
        this.markLine = {
          symbol: 'none',
          itemStyle: {
            normal: {
              lineStyle: {
                type: 'solid'
                // colors: ['#00A1FF', '#F5B049', '#009999'],
              },
              label: {
                // show: false,
                position: 'start',
                formatter: '{c} '
              }
            }
          },
          data: [
            {
              lineStyle: {
                color: '#00A1FF'
              },
              yAxis: sill
            },
            {
              lineStyle: {
                color: '#F5B049'
              },
              yAxis: GranuleSill
            },
            {
              lineStyle: {
                color: '#009999'
              },
              yAxis: HydrocarbonSill
            }
          ]
        }
      }
      if (owner) {
        this.owner = getPname(this.customerTree, owner, 'Org').join('/')
      }
      const para = {LocaleId: this.LocaleId}
      if (!this.filter.timerange) {
        this.filter.timerange = []
        const now = new Date()
        now.setDate(now.getDate() - 1)
        now.setHours(0, 0, 0, 0)
        this.filter.timerange[1] = now.getTime()
        now.setDate(now.getDate() - 30)
        this.filter.timerange[0] = now.getTime()
      }
      para['AcquitAtBegin'] = this.filter.timerange[0] / 1000
      para['AcquitAtEnd'] = this.filter.timerange[1] / 1000
      this.$post('admin/queryDataDetectorDaily', para).then((res) => {
        this.dataOption.rows = []
        let data
        for (
          var i = para['AcquitAtBegin'];
          i <= para['AcquitAtEnd'];
          i += 86400
        ) {
          data = res.find((e) => e.AcquitAt === i)
          this.dataOption.rows.push({
            采集时间: dateFormater(i, false),
            油烟折算浓度: data ? data.CEmissions : 0,
            颗粒物折算浓度: data ? data.CGranule : 0,
            非甲烷总烃折算浓度: data ? data.CHydrocarbon : 0
          })
        }
      })
      this.dlg.visible = true
    },
    handleExport() {
      export_json(
        dateFormater(this.filter.timerange[0] / 1000, false) +
        '至' +
        dateFormater(this.filter.timerange[1] / 1000, false) +
        '-' +
        this.mn,
        this.dataOption.rows,
        {
          油烟折算浓度: '油烟折算浓度',
          颗粒物折算浓度: '颗粒物折算浓度',
          非甲烷总烃折算浓度: '非甲烷总烃折算浓度',
          采集时间: '采集时间'
        }
      )
    },
    handleDownload(exportSize) {
      this.exportLoading = true
      var para = {
        StartAt: 0,
        Size: exportSize,
        Typ: 2,
        SortBy: this.filter.by,
        SortMode: this.filter.mode
      }
      para.Param = {
        IsDownload: true
      }
      if (this.filter.HealthCodeColor) {
        para.Param['HealthCodeColor'] = this.filter.HealthCodeColor
      }
      if (this.filter.mn) {
        para.Param['mn'] = this.filter.mn
      }
      if (this.filter.owner && this.filter.owner.length !== 0) {
        para.Param['Owner'] = join(this.filter.owner)
      }
      if (!this.filter.timerange) {
        this.filter.timerange = []
        const now = new Date()
        now.setDate(now.getDate() - 1)
        now.setHours(0, 0, 0, 0)
        this.filter.timerange[1] = now.getTime()
        now.setDate(now.getDate() - 30)
        this.filter.timerange[0] = now.getTime()
      }
      para.Param['StartAt'] = this.filter.timerange[0] / 1000
      para.Param['EndAt'] = this.filter.timerange[1] / 1000
      para.Param['LocaleWithDevice'] = !this.filter.LocaleWithDevice
      this.$post('admin/listHealthCode', para).then((res) => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
        this.exportLoading = false
      })
    },
    handleRefresh() {
      this.filter.page = 1
      this.filter.HealthCodeColor = null
      this.filter.mn = null
      this.filter.owner = null
      this.getData()
    },
    handlePageChange(val, field) {
      this.filter[field] = val
      this.getData()
    },
    handlerClose: function () {
      if (this.changeState) {
        this.changeState = false
        this.handleRefresh()
      }
      this.filter.timerange = null
      this.device = []
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
    },
    handleCodeView(id, mode) {
      this.$refs['managementViewDialog'].dealDialog(mode, { id: id })
    },
    handlePdfRecord(data) {
      this.NotificationDialog.data = {...data, Id: data.LocaleId}
      this.NotificationDialog.visible = true
    },
    handleAddHealthCode() {
      const payload = {
        records: [{
            LocaleId: this.healthCodeInfo.LocaleId,
            Mn: this.healthCodeInfo.MN,
            Owner: this.healthCodeInfo.Owner,
            StartAt: this.healthCodeInfo.StartAt,
            ExcessDays: this.healthCodeInfo.ExcessNum || 0,
            CompliantDays: this.healthCodeInfo.CompliantDays || 0,
            FilterAbnormallyUsedDays: this.healthCodeInfo.FilterAbnormallyUsedDays || 0,
            OfflineCausedByShopDays: this.healthCodeInfo.OfflineCausedByShopDays || 0
        }]
      }
      this.$post('admin/createHealthCodeRecord', payload).then(res => {
          this.$message({
            type: 'success',
            message: res
          })
          this.healthCodeInfo = {}
          this.HealthCodeRecordDialog.visible = false
          this.handleRefresh()
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.fa-exclamation-triangle {
  color: #ebb460;
}

.fa-bell {
  color: #f56c6c;
}

.el-icon-s-data {
  color: #8896b3;
}

.el-icon-orange {
  color: #409eff;
}

// td .input-box {
//    display: none
// }
td .input-box input {
  border: none;
  width: 100%;
  height: 100%;
}

// 查看图片
.preview_image_box {
  position: relative;
  // z-index: 1;
  .el-button {
    position: relative;
    // z-index: 2;
  }

  .el-image {
    position: absolute;
    // z-index: 10;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .el-image__inner {
    opacity: 0;
  }
}
</style>
