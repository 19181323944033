<template>
  <div>
    <el-dialog
      title="监测点下线申请"
      :visible.sync="visible"
      width="40%"
      :before-close="handleClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-form-item label="请上传证明材料" prop="fileList">
          <el-upload
            ref="upload"
            list-type="picture-card"
            accept=".jpg,.jpeg,.png"
            :action="upload()"
            :on-success="handleAvatarSuccess"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="tip">（支持jpg、jpeg、png）</div>
            <div slot="file" slot-scope="{file}">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url" alt=""
              >
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-delete"
                  @click="removeImg(file)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="原因" prop="reason">
          <el-input style="width:300px;" v-model="ruleForm.reason" placeholder="请输入原因"/>
        </el-form-item>
        <el-form-item label="是否已拆机" prop="is_disassemble">
          <el-select v-model="ruleForm.is_disassemble" style="width:300px;">
            <el-option label="是" :value="1"/>
            <el-option label="否" :value="0"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit('ruleForm')">提交</el-button>
          <el-button @click="handleClose()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import conf from "@/config";

export default {
  name: "OfflineApplyDialog",
  props: ['visible', 'localeId'],
  data() {
    return {
      ruleForm: {
        reason: "",
        is_disassemble: 0,
        fileList: [],
      },
      rules: {
        reason: [
          { required: true, message: '请输入下架原因',  trigger: 'blur' }
        ],
        is_disassemble: [
          { required: true, message: '请选择是否拆机', trigger: 'change' }
        ],
        fileList: [
          { required: true, message: '请选择上传图片', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleClose(formName) {
      this.$refs.upload.uploadFiles = []
      this.ruleForm = {
        reason: null,
        is_disassemble: null,
        fileList: [],
      }
      this.$emit("handleClose")
    },
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.fileList.push(res.Data)
    },
    removeImg(file, fileList) {
      let uploadFile = this.$refs.upload.uploadFiles
      let index = uploadFile.indexOf(file)
      uploadFile.splice(index, 1)
      this.ruleForm.fileList.splice(index, 1)
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ruleForm = Object.assign({}, this.ruleForm)
          ruleForm.locale_id = this.localeId
          ruleForm.pics = this.ruleForm.fileList
          let para = new URLSearchParams()
          for (let key in ruleForm) {
            para.append(key, ruleForm[key])
          }
          this.$post('admin/offline_apply/add', para).then(res => {
            this.$message.success('提交成功')
            this.$refs.upload.uploadFiles = []
            this.ruleForm = {
              reason: null,
              is_disassemble: null,
              fileList: [],
            }
            this.$emit("handleClose")
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>
<style scoped lang="scss">

</style>
